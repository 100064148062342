.skill-card {
  position: relative;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  line-height: 1;
}

.skill-card h2 {
  color: black;
  margin-bottom: 0;
}

.skill-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.skill-card:hover .skill-card__header {
  height: 40px;
}
.skill-card:hover .skill-card__header .skill-card__icon {
  -webkit-transform: translateX(-50%) translateY(-50%) scale(3);
          transform: translateX(-50%) translateY(-50%) scale(3);
}
.skill-card:hover .skill-card__body {
  height: 170px;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition-timing-function: cubic-bezier(0, 1.5, 1, 1);
          transition-timing-function: cubic-bezier(0, 1.5, 1, 1);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(1) {
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(2) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(3) {
  -webkit-transition-delay: 0.75s;
          transition-delay: 0.75s;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(4) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(5) {
  -webkit-transition-delay: 1.25s;
          transition-delay: 1.25s;
}
.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(6) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.skill-card .skill-card__header {
  position: relative;
  height: 170px;
  margin: -20px -20px 20px -20px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  overflow: hidden;
}
.skill-card .skill-card__header .skill-card__icon {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  display: block;
  width: 128px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.skill-card .skill-card__body {
  height: 45px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  overflow: hidden;
}
.skill-card .skill-card__body .skill-card__title {
  font-size: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
}
.skill-card .skill-card__body .skill-card__duration {
  color: #9E9E9E;
  font-style: italic;
}
.skill-card .skill-card__body .skill-card__knowledge {
  margin-top: 10px;
  color: #424242;
}
.skill-card .skill-card__body .skill-card__knowledge li {
  margin-top: 5px;
  -webkit-transition-duration: 0;
          transition-duration: 0;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.skill-card .docker {
  background-color: #1e79a0;
}

.skill-card .k8s {
  background-color: #25637e;
}

.skill-card .mysql, .skill-card .apache, .skill-card .go {
  background-color: #ededed;
}

.skill-card .python {
  background-color: #faf4c0;
}

.skill-card .ubuntu {
  background-color: #ff794a;
}

.skill-card .debian {
  background-color: #d19aaa;
}

.skill-card .nginx, .skill-card .mongo {
  background-color: #8aebb3;
}

.skill-card .gitlab {
  background-color: #ff8d62;
}

.skill-card .cloudflare {
  background-color: #d1580c;
}

.skill-card .ansible {
  background-color: #bdbdbd;
}
