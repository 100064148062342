.skill-card {
  cursor: pointer;
  background-color: #fff;
  padding: 20px;
  line-height: 1;
  position: relative;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.skill-card h2 {
  color: #000;
  margin-bottom: 0;
}

.skill-card:hover {
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.skill-card:hover .skill-card__header {
  height: 40px;
}

.skill-card:hover .skill-card__header .skill-card__icon {
  transform: translateX(-50%)translateY(-50%)scale(3);
}

.skill-card:hover .skill-card__body {
  height: 170px;
}

.skill-card:hover .skill-card__body .skill-card__knowledge li {
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1.5, 1, 1);
  transform: translateX(0);
}

.skill-card:hover .skill-card__body .skill-card__knowledge li:first-child {
  transition-delay: .25s;
}

.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(2) {
  transition-delay: .5s;
}

.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(3) {
  transition-delay: .75s;
}

.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(4) {
  transition-delay: 1s;
}

.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(5) {
  transition-delay: 1.25s;
}

.skill-card:hover .skill-card__body .skill-card__knowledge li:nth-child(6) {
  transition-delay: 1.5s;
}

.skill-card .skill-card__header {
  height: 170px;
  margin: -20px -20px 20px;
  transition: height .5s;
  position: relative;
  overflow: hidden;
}

.skill-card .skill-card__header .skill-card__icon {
  width: 128px;
  transition: transform .5s;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%)translateX(-50%);
}

.skill-card .skill-card__body {
  height: 45px;
  transition: height .5s;
  overflow: hidden;
}

.skill-card .skill-card__body .skill-card__title {
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: 600;
}

.skill-card .skill-card__body .skill-card__duration {
  color: #9e9e9e;
  font-style: italic;
}

.skill-card .skill-card__body .skill-card__knowledge {
  color: #424242;
  margin-top: 10px;
}

.skill-card .skill-card__body .skill-card__knowledge li {
  -webkit-transition-duration: 0;
  transition-duration: 0;
  margin-top: 5px;
  transition-delay: .5s;
  transform: translateX(-100%);
}

.skill-card .docker {
  background-color: #1e79a0;
}

.skill-card .k8s {
  background-color: #25637e;
}

.skill-card .mysql, .skill-card .apache, .skill-card .go {
  background-color: #ededed;
}

.skill-card .python {
  background-color: #faf4c0;
}

.skill-card .ubuntu {
  background-color: #ff794a;
}

.skill-card .debian {
  background-color: #d19aaa;
}

.skill-card .nginx, .skill-card .mongo {
  background-color: #8aebb3;
}

.skill-card .gitlab {
  background-color: #ff8d62;
}

.skill-card .cloudflare {
  background-color: #d1580c;
}

.skill-card .ansible {
  background-color: #bdbdbd;
}
/*# sourceMappingURL=index.fac598e5.css.map */
